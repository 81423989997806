import posthog from "posthog-js";

export function usePostHog() {
    if (
        process.env.VUE_APP_ENABLE_POSTHOG === "true" &&
        process.env.NODE_ENV !== "development"
    ) {
        posthog.init(process.env.VUE_APP_POST_HOG_PROJECT_TOKEN, {
            api_host: "https://us.i.posthog.com",
            capture_pageview: false,
        });
    }

    return {
        posthog,
    };
}
