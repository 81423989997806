export const debounce = <T extends (...args: any[]) => void>(
    func: T,
    wait: number
): ((...args: Parameters<T>) => void) => {
    let timeout: NodeJS.Timeout | null;

    return (...args: Parameters<T>): void => {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => func(...args), wait);
    };
};

export const isFirefox = () => {
    if (!navigator) return false;
    if (
        navigator.userAgent.toLowerCase().includes("firefox") ||
        "InstallTrigger" in window
    ) {
        return true;
    }
    return false;
};

export const retryOperation = async <T>(
    operation: () => Promise<T>,
    retries = 3,
    delay = 500
): Promise<T> => {
    for (let i = 0; i < retries; i++) {
        try {
            return await operation();
        } catch (error) {
            console.error(`Retry ${i + 1} failed:`, error);
            if (i < retries - 1) {
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }
    }
    throw new Error("All retries failed.");
};
