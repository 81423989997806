import { retryOperation } from "@/utilities/FunctionUtiltities";

export class trackingPixelService {
    constructor() {}

    trackEvent = async (
        eventType: TrackingEventType,
        parameters: TrackingParameters
    ) => {
        await retryOperation(async () => {
            // @ts-ignore
            if (window.virtuousTracker) {
                // @ts-ignore
                window.virtuousTracker.trackEvent(eventType, parameters);
            } else {
                const trackEventCallback = function () {
                    // @ts-ignore
                    window.virtuousTracker.trackEvent(eventType, parameters);
                    window.removeEventListener(
                        "virtuousTrackerInit",
                        trackEventCallback,
                        true
                    );
                };

                window.addEventListener(
                    "virtuousTrackerInit",
                    trackEventCallback,
                    true
                );
            }
        });
    };
}

export interface TrackingParameters {
    formId: string;
    formName: string;
}

export enum TrackingEventType {
    FormLoad = "FormLoad",
    FormStart = "FormStart",
    FormSubmit = "FormSubmit",
}
