import { ICustomComponent } from "@/builder/interfaces";

export enum InputType {
    Name,
    Email,
    PhoneNumber,
    Comments,
    Address,
    BusinessName,
}

export interface ITextInputCustomData {
    type: InputType;
    label: string;
    required: boolean;
    validation: string[];
}

export interface INameInputCustomData extends ITextInputCustomData {
    firstName: true;
    lastName: true;
    title: boolean;
    middleName: boolean;
    suffix: boolean;
    titleLabel: string;
    firstNameLabel: string;
    middleNameLabel: string;
    lastNameLabel: string;
    suffixLabel: string;
    titlePlaceholder: string;
    firstNamePlaceholder: string;
    middleNamePlaceholder: string;
    lastNamePlaceholder: string;
    suffixPlaceholder: string;
}

export interface IBusinessNameInputCustomData extends ITextInputCustomData {
    label: string;
    placeholder: string;
    onBehalfOfText: string;
}

export interface IAddressInputCustomData extends ITextInputCustomData {
    billingAddress1Label: string | null;
    billingAddress1Placeholder: string | null;
    billingAddress2Placeholder: string | null;
    billingCityPlaceholder: string | null;
    billingStatePlaceholder: string | null;
    billingPostalPlaceholder: string | null;
    billingCountryPlaceholder: string | null;

    enableShippingAddress: boolean;
    shippingAddress1Label: string | null;
    shippingAddress1Placeholder: string | null;
    shippingAddress2Placeholder: string | null;
    shippingCityPlaceholder: string | null;
    shippingStatePlaceholder: string | null;
    shippingPostalPlaceholder: string | null;
    shippingCountryPlaceholder: string | null;
}

export interface ITextInputComponent extends ICustomComponent {
    controls: {
        canMove: true;
        canEdit: true;
        canDelete: true;
        allowChildren: false;
        allowMultiple: true;
    };
    customData:
        | ITextInputCustomData
        | INameInputCustomData
        | IAddressInputCustomData;
}
