/**
 *
 * @param val
 * @returns string
 */
export const formatCurrency = (val: string) => {
    if (!val) return "0.00";

    const parsedAmount = parseFloat(
        `${!val ? 0 : val}`.replaceAll(",", "")
    ).toFixed(2);

    return Number(parsedAmount).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 *
 * @param hex
 * @param a
 * @returns rgba string
 */
export const hexToRgbA = (hex: string, a: number) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");

        const rgba = {
            r: (c >> 16) & 255,
            g: (c >> 8) & 255,
            b: c & 255,
            a: a,
        };

        return rgbaToString(rgba);
    }
    throw new Error("Bad Hex");
};

/**
 *
 * @param rgba
 * @returns string
 */
export const rgbaToString = (rgba: any) => {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
};

/**
 *
 * @param hex
 * @returns string
 */
export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? rgbToString({
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          })
        : null;
};

/**
 *
 * @param rgb
 * @returns string
 */
export const rgbToString = (rgb: any) => {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

export const truncate = (text: string, limit: number): string => {
    return text.length > limit ? text.slice(0, limit) : text;
};

export const sanitizeName = (name: string): string => {
    name = name.replaceAll("-", "");
    return truncate(name, 22);
};

export function encodeString(str: string | null): string {
    if (!str) {
        return "";
    }

    try {
        const utf8Encoder = new TextEncoder();
        const utf8Bytes = utf8Encoder.encode(str);

        // split into chunks to avoid URI length limits from large strings
        const chunkSize = 8192; // 8KB
        const encodedChunks: string[] = [];
        for (let i = 0; i < utf8Bytes.length; i += chunkSize) {
            const chunk = utf8Bytes.slice(i, i + chunkSize);
            encodedChunks.push(btoa(String.fromCharCode(...chunk)));
        }

        return encodedChunks.join(".");
    } catch (error) {
        console.error("Error encoding string:", error);
        throw new Error("Failed to encode string");
    }
}

export function decodeString(encoded: string | null): string {
    if (!encoded) {
        return "";
    }

    try {
        const encodedChunks = encoded.split(".");

        // decode each chunk and concatenate
        const decodedBytes: number[] = [];
        for (const chunk of encodedChunks) {
            const decodedChunk = atob(chunk);
            for (let j = 0; j < decodedChunk.length; j++) {
                decodedBytes.push(decodedChunk.charCodeAt(j));
            }
        }

        const bytes = new Uint8Array(decodedBytes);

        return new TextDecoder("utf-8").decode(bytes);
    } catch (error) {
        console.error("Error decoding string:", {
            error,
            encoded: encoded.substring(0, 100) + "...",
            length: encoded.length,
        });
        throw new Error("Failed to decode string");
    }
}

export function encodeJson(obj: any): string {
    try {
        // Convert to JSON string
        const jsonString = JSON.stringify(obj);
        // Encode JSON string
        return encodeString(jsonString);
    } catch (error) {
        console.error("Error encoding JSON:", error);
        throw new Error("Failed to encode JSON object");
    }
}

export function decodeJson(encoded: string): any {
    try {
        // Decode JSON string
        const jsonString = decodeString(encoded);
        // Parse JSON
        return JSON.parse(jsonString);
    } catch (error) {
        console.error("Error decoding JSON:", {
            error,
            encoded: encoded.substring(0, 100) + "...",
            length: encoded.length,
        });
        throw new Error("Failed to decode JSON object");
    }
}

export function toCamelCase(str: string): string {
    return str.replace(/([-_][a-z])/gi, ($1) =>
        $1.toUpperCase().replace("-", "").replace("_", "")
    );
}

export function toSnakeCase(str: string): string {
    return str
        .replace(/([A-Z])/g, "_$1")
        .replace(/^_/, "")
        .toLowerCase();
}

export function toTitleCase(str: string): string {
    return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
